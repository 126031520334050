import {
	Button,
	FeedbackChoicesTypes,
	ListSection,
	useCheckupPage,
	useContactExpertCard,
	FeedbackForm as FeedbackType,
} from '@home-diy-toolbox/web/contentful';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { FC, useContext, useState } from 'react';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import {
	AnalyticEventType,
	AnalyticViewType,
	useDispatchEvent,
	useViewAnalytics,
} from '@home-diy-toolbox/web/analytics';
import { getChatClient } from '../../../../features/chat/components';
import { Header, RelatedArticles } from '../components';
import { ContactExpertsDrawer } from '../../../../components/ContactExpertCard/ContactExpertsDrawer';
import { ContactExpertsModal } from '../../../../components/ContactExpertCard/ContactExpertsModal';
import { ContactExpertsCard } from '../results';
import { HeaderBreadcrumbs } from '../../../../components/HeaderBreadcrumbs';
import { ResultCard } from '../components/ResultCard';
import { BatteryResultV3Ids } from '../answers/BatteryResultV3';
import { Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	FeedbackComponentTypes,
	FeedbackTypes,
	FeedbackForm,
} from '../../../../components/FeedbackForm';
import { Spinner } from '../components/Spinner';

const ContentContainer = styled.div`
	${breakpoints.lg} {
		max-width: 41.75rem;
	}
`;

const BreadcrumbsContainer = styled.div`
	div {
		margin-bottom: 1.125rem;
	}
`;

const SpinnerContainer = styled.div`
	padding-top: 1rem;
`;

type Blocks = {
	relatedGuideTipsHeader: ListSection;
	showMoreTips: ListSection;
	feedbackForm: ListSection;
	checkupPositive: FeedbackType;
	checkupNegative: FeedbackType;
};

type Props = {
	resultId: BatteryResultV3Ids;
	tags: Set<string>;
};

const resultIdsWithExpertCard: BatteryResultV3Ids[] = [
	BatteryResultV3Ids.BATTERY_HEALTHY,
	BatteryResultV3Ids.NOT_ENOUGH_INFORMATION,
];

const ResultsPage: FC<Props> = ({ resultId, tags }) => {
	const pageContent = useCheckupPage(resultId);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [drawerIsOpen, setDrawerIsOpen] = useState(false);

	useViewAnalytics(AnalyticViewType.PAGE, {
		PageId: 'resultPage',
		BatteryCheckupResult: resultId,
		CheckupType: Checkups.BATTERY,
	});

	const contactExpertsCard = useContactExpertCard('talkToAnExpertV3');

	const theme = useContext(ThemeContext);
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop})`);

	const { dispatchEvent } = useDispatchEvent();

	const checkButtonAction = (button: Button) => {
		if (button.action === 'openChat') {
			getChatClient().openMessagingOverlay();
		} else if (button.action === 'phoneNumber') {
			window.location.href = 'tel:' + button.telephoneNumber;
		} else if (button.action === 'externalLink') {
			window.open(button.url, '_blank').focus();
		} else if (button.action === 'internalLink') {
			window.open(button.url, 'self');
		}
	};

	if (!pageContent) {
		return (
			<SpinnerContainer>
				<Spinner />
			</SpinnerContainer>
		);
	}

	const {
		relatedGuideTipsHeader,
		showMoreTips,
		checkupPositive,
		checkupNegative,
		feedbackForm,
	} = pageContent.blocks.reduce((prev, curr) => {
		prev[curr.entryName] = curr;
		return prev;
	}, {} as Blocks);

	const resultCard = pageContent.resultCards?.[0] ?? null;

	return (
		<>
			<ContentContainer>
				<BreadcrumbsContainer>
					<HeaderBreadcrumbs
						steps={[
							{ path: '/device-care', label: 'Battery health' },
							{ label: 'Battery check' },
						]}
					/>
				</BreadcrumbsContainer>
				{Boolean(pageContent.heading) && (
					<Header weight="heavy" heading={pageContent.heading} size={5} />
				)}
				{Boolean(pageContent.heading) && (
					<Header weight="base" heading={pageContent.subheading} size={3} />
				)}
				{Boolean(resultCard) && (
					<ResultCard
						cardHeading={resultCard.cardHeading}
						cardSubheading={resultCard.cardDescription}
						cardRichTextDescription={resultCard.cardRichTextDescription}
						cardRichTextDescription2={resultCard.cardRichTextDescription2}
						imageUrl={resultCard.coverImage.asset.url}
						imageAlt={resultCard.coverImage.alternativeText}
						buttonText={resultCard.cardCta.copy}
						onButtonClick={() => {
							checkButtonAction(resultCard.cardCta);

							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'resultCard',
								BatteryCheckupResult: resultId,
								ResultCardEntryName: resultCard.entryName,
								PageId: 'resultPage',
							});
						}}
					/>
				)}

				{resultIdsWithExpertCard.includes(resultId) && Boolean(contactExpertsCard) && (
					<ContactExpertsCard
						mainHeading={contactExpertsCard.mainHeading}
						cardHeading={contactExpertsCard.cardHeading}
						cardSubheading={contactExpertsCard.cardDescription}
						imageUrl={contactExpertsCard.coverImage.asset.url}
						imageAlt={contactExpertsCard.coverImage.alternativeText}
						isDesktop={isDesktop}
						resultId={resultId}
						openCardButtonCtAs={contactExpertsCard.openCardButtons}
						onClickBanner={() => {
							if (contactExpertsCard.cardCtAs.length > 1) {
								isDesktop ? setModalIsOpen(true) : setDrawerIsOpen(true);
							} else {
								checkButtonAction(contactExpertsCard.cardCtAs[0]);
							}

							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'batteryResultsLiveExpertCard',
								BatteryCheckupResult: resultId,
								PageId: 'resultPage',
							});
						}}
					/>
				)}
				{Boolean(relatedGuideTipsHeader) && ( // hide articles if heading is not exist
					<RelatedArticles
						relatedGuidesTitle={relatedGuideTipsHeader?.heading || ''}
						relatedGuidesLink={showMoreTips?.heading || ''}
						answerTags={tags}
						resultId={resultId}
					/>
				)}
				{Boolean(feedbackForm) && (
					<FeedbackForm
						variant={FeedbackComponentTypes.STARS}
						type={FeedbackTypes.SESSION}
						feedbackTitle={feedbackForm.heading}
						positiveForm={{
							commentsPlaceholder: checkupPositive.commentsPlaceholder,
							completeMessageCopy: checkupPositive.completeMessageCopy,
							feedbackChoices: checkupPositive.feedbackChoices.map((choice) => ({
								id: choice.entryName,
								label: choice.copy,
							})),
							feedbackChoicesType: FeedbackChoicesTypes.NEW,
							formId: checkupPositive.entryName,
							header: checkupPositive.header,
							subheader: checkupPositive.subheader,
							submitButtonCopy: checkupPositive.submitCta.copy,
						}}
						negativeForm={{
							commentsPlaceholder: checkupNegative.commentsPlaceholder,
							completeMessageCopy: checkupNegative.completeMessageCopy,
							feedbackChoices: checkupNegative.feedbackChoices.map((choice) => ({
								id: choice.entryName,
								label: choice.copy,
							})),
							feedbackChoicesType: FeedbackChoicesTypes.NEW,
							formId: checkupNegative.entryName,
							header: checkupNegative.header,
							subheader: checkupNegative.subheader,
							submitButtonCopy: checkupNegative.submitCta.copy,
						}}
						analyticsExtras={{ CheckupType: Checkups.BATTERY }}
					/>
				)}
			</ContentContainer>
			{Boolean(contactExpertsCard) && (
				<>
					<ContactExpertsModal
						isOpen={modalIsOpen}
						title={contactExpertsCard.popupTitle}
						cardCtas={contactExpertsCard.cardCtAs}
						onCloseClick={() => {
							setModalIsOpen(false);
						}}
						analyticExtras={{
							BatteryCheckupResult: resultId,
							PageId: 'resultPage',
						}}
					/>
					<ContactExpertsDrawer
						title={contactExpertsCard.popupTitle}
						cardCtas={contactExpertsCard.cardCtAs}
						isOpen={drawerIsOpen}
						onOpenUpdate={() => {
							setDrawerIsOpen(false);
						}}
						analyticExtras={{
							BatteryCheckupResult: resultId,
							PageId: 'resultPage',
						}}
					/>
				</>
			)}
		</>
	);
};

export default ResultsPage;
