import { useQuery } from 'urql';
import { checkupPageQuery, checkupPageQueryWithPartnerTag } from './queries';
import { CheckupPage } from './models';
import { useValidateMxContext } from '../useValidateMxContext';
import { transformGraphqlEntry } from './utils';

export const useCheckupPage = (
	checkupPage: string,
	filterByPartner = false
): CheckupPage => {
	const { language, client: partner } = useValidateMxContext();

	const [response] = useQuery({
		query: filterByPartner ? checkupPageQueryWithPartnerTag : checkupPageQuery,
		variables: {
			locale: language,
			checkupPage,
			partnerTag: `partner_${partner}`,
		},
		pause: !checkupPage,
	});

	if (!response.data) return null;

	const transformedEntry = transformGraphqlEntry(
		response.data.checkupPageCollection.items[0]
	);

	transformedEntry.ctas = transformedEntry?.ctas?.reduce((acc, button) => {
		acc[button.entryName] = button;
		return acc;
	}, {});

	return transformedEntry;
};
