import { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { createGlobalStyle } from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import 'swiper/css';
import 'swiper/css/navigation';

const SwiperCustomStyles = createGlobalStyle`
	.swiper-button-prev, .swiper-button-next {
     	color: ${colors.black} !important;
		transition: opacity .2s ease-out;
		opacity: 0.4;

		&:hover {
			opacity: 1;
		}

		&:after {
			--swiper-navigation-size: 1.5625rem;
		}
	}

	.swiper-button-disabled {
		display: none !important;
	}
`;

export const Carousel = ({
	children,
	...props
}: SwiperOptions & { children?: ReactNode }) => {
	return (
		<>
			<SwiperCustomStyles />
			<Swiper modules={[Navigation]} grabCursor={true} navigation {...props}>
				{children}
			</Swiper>
		</>
	);
};

export const CarouselSlide = SwiperSlide;
