import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import React from 'react';
import styled from 'styled-components';
import { colors, Link, Text } from '@soluto-private/mx-asurion-ui-react';
import { InputContainer } from './InputContainer';

const Container = styled(InputContainer)`
	&& {
		display: flex;
	}
`;

const CoverImage = styled.img`
	width: 12.5rem;
	max-width: 30%;
	object-fit: cover;
	border-radius: 0.625rem 0 0 0.625rem;
`;

const TextContent = styled.div`
	padding: 1rem;
	border: 1px solid ${colors.neutralDeep};
	border-radius: 0 0.625rem 0.625rem 0;
`;

const Title = styled(Text)`
	&& {
		margin-bottom: 0.5rem;
	}
`;

const Description = styled(Text)`
	&& {
		margin-bottom: 0.5rem;
		color: #5d5e61;
		line-height: 1.3;
		font-size: 0.875rem;
	}
`;

const ButtonLink = styled(Link)`
	&& {
		display: inline-flex;
	}
`;

export const StartClaimCard = () => {
	const { resultCards } = useCheckupPage('batteryCheckupLayout');
	const resultCard = resultCards[0];

	return (
		<Container>
			<CoverImage
				src={resultCard.coverImage.asset.url}
				alt={resultCard.coverImage.alternativeText}
			/>
			<TextContent>
				<Title forwardedAs="h1" size={4} weight="heavy">
					{resultCard.cardHeading}
				</Title>
				<Description forwardedAs="p">{resultCard.cardDescription}</Description>
				<ButtonLink
					href={resultCard.cardCta.url}
					variant="button"
					btnVariantProps={{
						btnVariant: 'outline',
						btnSize: 'small',
					}}
					target="_blank"
				>
					{resultCard.cardCta.copy}
				</ButtonLink>
			</TextContent>
		</Container>
	);
};
