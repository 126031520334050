import styled from 'styled-components';
import { Button, Text } from '@soluto-private/mx-asurion-ui-react';
import { RichText } from '@home-diy-toolbox/web/contentful';
import { getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { BLOCKS, Document } from '@contentful/rich-text-types';

const MainContainer = styled.div`
	width: 100%;
	margin-bottom: 1.5rem;

	@media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 21.1875rem;
`;

const Card = styled.div`
	border: 1px solid #c6c6ca;
	border-radius: 0.5rem;
	margin-bottom: 0.264rem;
	padding-bottom: 1rem;
	overflow: hidden;
`;

const ResultImg = styled.img`
	width: 100%;
`;

const TextContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
`;

const TextHeading = styled(Text)`
	&& {
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('heavy')};
		flex-grow: 1;
	}
`;

const TextSubHeading = styled(Text)`
	&& {
		font-size: ${getFontSize(1)};
		font-weight: ${getFontWeight('base')};
		color: #5d5e61;
	}
`;

const TextRichTextDescription1 = styled(Text)`
	&& {
		font-size: ${getFontSize(2)};
		font-weight: ${getFontWeight('base')};
	}
`;

const TextRichTextDescription2 = styled(Text)`
	&& {
		font-size: ${getFontSize(0)};
		font-weight: ${getFontWeight('base')};
		color: #5d5e61;
	}
`;

const TextHeadingContainer = styled.div`
	align-items: center;
	display: flex;
`;

const CtaButton = styled(Button)`
	&& {
		margin-left: 1rem;
	}
`;

interface Props {
	cardHeading: string;
	cardSubheading: string;
	cardRichTextDescription: Document;
	cardRichTextDescription2: Document;
	imageUrl: string;
	imageAlt: string;
	buttonText: string;
	onButtonClick: () => void;
}

export const ResultCard = ({
	cardHeading,
	cardSubheading,
	cardRichTextDescription,
	cardRichTextDescription2,
	imageUrl,
	imageAlt,
	buttonText,
	onButtonClick,
}: Props) => {
	return (
		<MainContainer>
			<Container>
				<Card>
					<ResultImg src={imageUrl} alt={imageAlt} />
					<TextContainer>
						<TextHeadingContainer>
							<TextHeading>{cardHeading}</TextHeading>
						</TextHeadingContainer>
						<TextSubHeading>{cardSubheading}</TextSubHeading>
						<RichText
							document={cardRichTextDescription}
							renderOptionsOverride={{
								renderNode: {
									[BLOCKS.PARAGRAPH]: (_, children) => {
										return (
											<TextRichTextDescription1>{children}</TextRichTextDescription1>
										);
									},
								},
							}}
						/>
						<RichText
							document={cardRichTextDescription2}
							renderOptionsOverride={{
								renderNode: {
									[BLOCKS.PARAGRAPH]: (_, children) => {
										return (
											<TextRichTextDescription2>{children}</TextRichTextDescription2>
										);
									},
								},
							}}
						/>
					</TextContainer>
					<CtaButton size="small" onClick={() => onButtonClick()}>
						{buttonText}
					</CtaButton>
				</Card>
			</Container>
		</MainContainer>
	);
};
