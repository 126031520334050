import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BLOCKS } from '@contentful/rich-text-types';
import {
	Breadcrumb,
	Link,
	Text,
	getFontSize,
	breakpoints,
} from '@soluto-private/mx-asurion-ui-react';
import { useFlow } from '@home-diy-toolbox/apps/device-care/flow-provider';
import { CheckupPage, QuestionBlock, RichText } from '@home-diy-toolbox/web/contentful';
import { OSCheckCard } from './OSCheckCard';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { getModelFromMake } from '../../../../utils/device-utils';
import { Carousel, CarouselSlide } from '../../../../components/Carousel';
import { usePageStack } from '../hooks';

const Container = styled.div`
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 3rem;
`;

const Header = styled(Text)`
	&& {
		font-size: ${getFontSize(5)};

		${breakpoints.md2} {
			font-size: ${getFontSize(6)};
		}
	}
`;

const Question = styled(Text)`
	&& {
		margin-bottom: 1rem;
	}
`;

type OSCheckSectionProps = {
	pageContent: CheckupPage;
	hideOptions: boolean;
};

export const OSCheckSection = ({ pageContent, hideOptions }: OSCheckSectionProps) => {
	const stack = usePageStack('BatteryCheckupV3PageStack');
	const { flow: appFlow, setSelectedOS } = useFlow();
	const { deviceOS, deviceMake } = appFlow.sessionData;
	const history = useHistory();
	const pageId = stack.peek();

	const { ctas, heading } = pageContent;

	const questionBlock = pageContent.blocks.find(
		(block) => block.entryName === 'osCheckSection'
	) as QuestionBlock;

	const selectedOS = `${deviceOS?.toLocaleLowerCase()}_${deviceMake?.toLocaleLowerCase()}`;
	const answers = questionBlock.answers?.map(
		(
			{
				fieldTitle: answerDeviceOsLabel,
				text: answerDeviceOsValue,
				value: answerDeviceMake,
			},
			i
		) => {
			const id = `${answerDeviceOsValue?.toLocaleLowerCase()}_${answerDeviceMake?.toLocaleLowerCase()}`;
			return {
				id,
				answerDeviceOsLabel,
				answerDeviceOsValue,
				answerDeviceMake,
			};
		}
	);

	const initialSlide = answers.findIndex(({ id }) => id === selectedOS);

	return (
		<Container>
			<Breadcrumb>
				<Link onClick={() => history.push('/')}>{ctas.batteryHealth?.copy}</Link>
				<Text>{ctas.batteryCheck?.copy}</Text>
			</Breadcrumb>
			<RichText
				document={heading}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<Header forwardedAs="h1" weight="heavy">
								{children}
							</Header>
						),
					},
				}}
			/>
			{!hideOptions ? (
				<div>
					<Question forwardedAs="h2" size={4} weight="feather">
						{questionBlock.question}
					</Question>
					{answers ? (
						<Carousel
							slidesPerView={1}
							spaceBetween={20}
							breakpoints={{
								480: { slidesPerView: 2 },
								640: { slidesPerView: 3 },
							}}
							initialSlide={initialSlide}
						>
							{answers.map(
								(
									{ id, answerDeviceOsLabel, answerDeviceOsValue, answerDeviceMake },
									i
								) => {
									return (
										<CarouselSlide key={id}>
											<OSCheckCard
												deviceOsLabel={answerDeviceOsLabel}
												deviceOsValue={answerDeviceOsValue}
												deviceMake={answerDeviceMake}
												isSelected={id === selectedOS}
												onChangeDevice={setSelectedOS}
												actionId={`${GenericPageIds.OS_SELECTION}_${id}`}
												analyticExtras={{
													OsDeviceAnswer: id,
													DeviceMake: answerDeviceMake,
													DeviceModel: getModelFromMake(answerDeviceMake),
													DeviceOS: answerDeviceOsValue,
													PageId: pageId,
												}}
											/>
										</CarouselSlide>
									);
								}
							)}
						</Carousel>
					) : null}
				</div>
			) : null}
		</Container>
	);
};
