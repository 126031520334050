const CheckupPageFragments = `
items {
      entryName
      type
      heading {
        json
      }
      subheading {
        json
      }
      description {
        json
      }
      blocksCollection {
        items {
          ... on RichTextCopy {
            entryName
            copy {
              json
              links {
                assets {
                  block {
                    sys {
                      id
                    }
                    title
                    url
                  }
                }
              }
            }
          }
          ... on Image {
            __typename
            entryName
            asset {
              url
            }
            alternativeText
          }
          ... on SimpleCopy {
            __typename
            entryName
            simpleCopy: copy
          }
          ... on QuestionBlock {
            __typename
            entryName
            question
            questionDescription {
              __typename
              json
            }
            answersCollection(
              # Added limit: Query cannot be executed. The maximum allowed complexity for a query is 11000 but it was 30315. Simplify the query e.g. by setting lower limits for collections.
              limit: 20 
            ) {
              items {
                entryName
                type
                text
                description
                placeholder
                fieldTitle
                value
                tags
                answerImage {
                  entryName
                  asset {
                    url
                  }
                  alternativeText
                }
              }
            }
          }
          ... on FeedbackForm {
            entryName
            header
            subheader
            feedbackChoicesCollection {
              items {
                entryName
                copy
              }
            }
            commentsPlaceholder
            completeMessageCopy
            submitCta {
              copy
            }
          }
          ... on ListSection {
            entryName
            heading
            showMoreCta {
              entryName
              copy
              url
              telephoneNumber
              action
            }
            viewAllLink
          }
        }
      }
      bannersCollection(
        limit: 1
        where: {contentfulMetadata: {tags: {id_contains_all: [$partnerTag]}}}
      ) {
        items {
          entryName
          type
          title
          description
          mainCta {
            entryName
            copy
            url
            telephoneNumber
            action
          }
          secondaryCta {
            entryName
            copy
            url
            telephoneNumber
            action
          }
          bannerImage {
            entryName
            asset {
              url
            }
            alternativeText
          }
          cardImage {
            entryName
            asset {
              url
            }
            alternativeText
          }
        }
      }
      ctasCollection {
        items {
          entryName
          copy
          url
          telephoneNumber
          action
        }
      }
      footnote {
        json
      }
      resultCardsCollection(
        limit: 1
        where: {contentfulMetadata: {tags: {id_contains_all: [$partnerTag]}}}
      ) {
        items {
          entryName
          coverImage {
            asset {
              url
            }
            alternativeText
          }
          badgeName
          cardHeading
          cardDescription
          cardRichTextDescription {
            json,
          }
          cardRichTextDescription2 {
            json,
          }
          cardCta {
            entryName
            copy
            url
            telephoneNumber
            action
          }
        }
      }
      serviceListCollection {
        items {
          id
          checkupType
          title
          description
          cover {
            title
            description
            url
          }
          action
          location
          tags
        }
      }
    }
  }
`;

export const checkupPageQuery = `
query CheckupPage($checkupPage: String!, $partnerTag: String!, $locale: String!) {
  checkupPageCollection(
    limit: 1
    where: {entryName: $checkupPage}
    locale: $locale
  ) {
    ${CheckupPageFragments}
}`;

export const checkupPageQueryWithPartnerTag = `
query CheckupPage($checkupPage: String!, $partnerTag: String!, $locale: String!) {
  checkupPageCollection(
    limit: 1
    where: {entryName: $checkupPage, contentfulMetadata: {tags: {id_contains_all: [$partnerTag]}}}
    locale: $locale
  ) {
    ${CheckupPageFragments}
}`;
