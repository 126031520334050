const linkEntries = (content, links) => {
	if (!links || !links.assets) return content;

	const linkMap = new Map();
	for (const asset of links.assets.block) {
		linkMap.set(asset.sys.id, {
			tile: asset.title,
			file: {
				url: asset.url,
			},
		});
	}

	return content.map((entry) => {
		if (entry.nodeType === 'embedded-asset-block') {
			entry.data.target.fields = linkMap.get(entry.data.target.sys.id);
		}

		return entry;
	});
};

export const transformGraphqlEntry = (entry: unknown) => {
	if (typeof entry !== 'object' || !entry) return entry;

	if (Array.isArray(entry)) {
		return entry.map((x) => transformGraphqlEntry(x));
	}

	const holder = {};
	for (const key of Object.keys(entry)) {
		const value = entry[key];

		const isCollection = value?.items && key.endsWith('Collection');
		if (isCollection) {
			holder[key.replace('Collection', '')] = transformGraphqlEntry(value.items);
			continue;
		}
		const isRichText = value?.json && value.json.content && value.json.nodeType;
		if (isRichText) {
			value.json.content = linkEntries(value.json.content, value.links);
			holder[key] = value.json;
			continue;
		}

		holder[key.replace('__', '')] = transformGraphqlEntry(value);
	}

	return holder;
};
