import { Button, Link, colors, Text } from '@soluto-private/mx-asurion-ui-react';
import styled, { css } from 'styled-components';
import { breakpoints, getFontSize } from '@soluto-private/mx-asurion-ui-react-v3';
import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { getChatClient } from '../../../../features/chat/components';
import { withAnalytics } from '@home-diy-toolbox/web/analytics';
import { Pages } from '../pages';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Wrapper = styled.div`
	display: grid;
	background-color: ${colors.neutralBrightest};

	${breakpoints.md2} {
		flex-grow: 1;
		flex-shrink: 0;
	}
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 0;
	margin: 0 auto;
	max-width: 26.4375rem;

	${breakpoints.md2} {
		max-width: unset;
		padding: 3rem 2.25rem 0;
	}
`;

const StyledText = styled(Text)`
	&& {
		display: block;
		color: ${colors.neutralDeepest};
	}
`;

const Title = styled(StyledText)`
	&& {
		max-width: 100%;
		display: block;
		font-size: ${getFontSize(3)};

		${breakpoints.md2} {
			max-width: 80%;
			font-size: ${getFontSize(5)};
		}
	}
`;

const Subtitle = styled(StyledText)`
	&& {
		display: block;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
		max-width: 100%;
		font-size: ${getFontSize(2)};

		${breakpoints.md2} {
			max-width: 80%;
			margin-bottom: 1.5rem;
			font-size: ${getFontSize(3)};
		}
	}
`;

const Buttons = styled.div`
	gap: 1rem;
	grid-template-columns: repeat(1, 1fr);
	display: grid;
	max-width: 80%;
	margin-bottom: 1.5rem;

	${breakpoints.md2} {
		display: flex;
		flex-wrap: wrap;
	}
`;

const ButtonStyle = css`
	min-width: fit-content;
	max-width: 15.625rem;
	box-sizing: border-box;
	justify-self: start;
`;

const ChatButton = styled(withAnalytics(Button))`
	&& {
		${ButtonStyle}
	}
`;

const CallButton = styled(withAnalytics(Link))`
	&& {
		${ButtonStyle}
	}
`;

const Model = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-top;
	align-self: start;
	margin: 0;

	& > img {
		width: 100%;
		vertical-align: top;
	}
`;

const FullBodyModel = styled(Model)`
	display: none;

	${breakpoints.md2} {
		display: block;
	}
`;

const HalfBodyModel = styled(Model)`
	display: block;

	${breakpoints.md2} {
		display: none;
	}
`;

export const Banner = ({ currPage }: { currPage: Pages }) => {
	const pageContent = useCheckupPage('batteryCheckupLayout');
	const banner = pageContent?.banners[0];

	if (!banner) return null;

	const title = banner.title;
	const subtitle = banner.description;
	const chatButton = banner.mainCta
		? {
				text: banner.mainCta.copy,
				onClick: () => {
					const client = getChatClient();
					client.openMessagingOverlay();
				},
		  }
		: null;

	const callButton = banner.secondaryCta
		? {
				href: `tel:${banner.secondaryCta.telephoneNumber}`,
				text: banner.secondaryCta.copy,
		  }
		: null;

	const fullBodyImage = banner.bannerImage.asset.url;
	const fullBodyImageAlt = banner.bannerImage.alternativeText;
	const halfBodyImage = banner.cardImage.asset.url;
	const halfBodyImageAlt = banner.cardImage.alternativeText;

	return (
		<Container>
			<Wrapper>
				<Details>
					<Title weight="heavy">{title}</Title>
					<Subtitle size={3}>{subtitle}</Subtitle>
					<Buttons>
						{chatButton ? (
							<ChatButton
								color="primary"
								variant="default"
								actionId="chatBtnClick"
								analyticExtras={{
									PageId: currPage,
								}}
								onClick={() => chatButton.onClick()}
							>
								{chatButton.text}
							</ChatButton>
						) : null}
						{callButton ? (
							<CallButton
								color="secondary"
								variant="button"
								href={callButton.href}
								actionId="callBtnClick"
								analyticExtras={{
									PageId: currPage,
								}}
								btnVariantProps={{ btnSize: 'medium', btnVariant: 'outline' }}
							>
								{callButton.text}
							</CallButton>
						) : null}
					</Buttons>
					<FullBodyModel>
						<img src={fullBodyImage} alt={fullBodyImageAlt} />
					</FullBodyModel>
					<HalfBodyModel>
						<img src={halfBodyImage} alt={halfBodyImageAlt} />
					</HalfBodyModel>
				</Details>
			</Wrapper>
		</Container>
	);
};
