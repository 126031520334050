import React from 'react';
import { RichText, RichTextCopy, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Modal } from '@soluto-private/mx-asurion-ui-react';
import { Spinner } from '../components/Spinner';

const BatteryMessages = ({ isOpen, onClose }) => {
	const batteryMessages = useCheckupPage('batteryMessages');

	if (!batteryMessages) return <Spinner />;

	const batteryMessagesDocument = (
		batteryMessages.blocks.find(
			(block) => block.entryName === 'batteryMessages'
		) as RichTextCopy
	)?.copy;

	return (
		<Modal
			title={documentToPlainTextString(batteryMessages.heading)}
			isOpen={isOpen}
			onCloseClick={() => onClose()}
		>
			{batteryMessagesDocument && (
				<RichText document={batteryMessagesDocument}></RichText>
			)}
		</Modal>
	);
};

export default BatteryMessages;
